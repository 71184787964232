export const useGeneralAgree = () => useState('agree', () => false)

// view diaries
export const useModalsDiary = () => useState('useModalsDiary', () => null)
export const useModalsDiaryShow = () => useState('useModalsDiaryShow', () => false)
export const useModalsDiaryModalRef = () => useState('useModalsDiaryModalRef', () => false)

// view shorts
export const useModalsShort = () => useState('useModalsShort', () => null)
export const useModalsShortShow = () => useState('useModalsShortShow', () => false)
export const useModalsShortModalRef = () => useState('useModalsShortModalRef', () => false)
export const useModalsShortModalList = () => useState('useModalsShortModalList', () => false)
export const useModalsShortModalListIndex = () => useState('useModalsShortModalListIndex', () => 0)
export const useModalsShortHidePlayerFunc = () => useState('useModalsShortHidePlayerFunc', () => () => {})
export const useModalsShortLoadMoreFunc = () => useState('useModalsShortLoadMoreFunc', () => () => {})

// edit shorts

export const useModalsShortEditShow = () => useState('useModalsShortEditShow', () => false)
export const useModalsShortEditId = () => useState('useModalsShortEditId', () => null)